import { types } from '../action.constants';

let defaultState = {
	toasts: [],
};

const addToast = (state, { toast }) => ({
	...state,
	toasts: [...state.toasts, toast],
});

const removeToast = (state, { id }) => ({
	...state,
	toasts: state.toasts.filter(toast => toast.id !== id),
});

export const snackbarReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case types.TOAST_ADD:
			return addToast(state, payload);
		case types.TOAST_REMOVE:
			return removeToast(state, payload);
		default:
			return state;
	}
};

export default snackbarReducer;
