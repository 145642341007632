import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { useDispatch } from 'react-redux';
import { faCamera, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultSrc } from 'core/helpers/image.helpers';
import { removeImage } from 'core/store/authentication/auth.actions';

import Button from 'components/Button/Button';
import { Col, Row } from 'components/Grid/Grid';

import { useImage } from '../../../../core/hooks/useImage/useImage';

import 'react-image-crop/dist/ReactCrop.css';

let fileUrl;
let element;

const Picture = ({ user, picture, submit }) => {
	const dispatch = useDispatch();
	const [src, setSrc] = useState();
	const [filename, setFilename] = useState();
	const image = useImage(picture);

	const [croppedImageUrl, setCroppedImageUrl] = useState();
	const [crop, setCrop] = useState({
		unit: '%',
		width: 100,
		aspect: 16 / 16,
	});
	const [blob, setBlob] = useState();

	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const reader = new FileReader();

			reader.addEventListener('load', () => setSrc(reader.result));
			reader.readAsDataURL(file);
			setFilename(file.name);
		}
	};

	const onCropChange = crop => {
		setCrop(crop);
	};

	const onImageLoaded = image => {
		element = image;
	};

	const onCropComplete = crop => {
		makeClientCrop(crop).then(croppedImageUrl => {
			setCroppedImageUrl(croppedImageUrl);
		});
	};

	const makeClientCrop = async crop => {
		if (element && crop.width && crop.height) {
			return await getCroppedImg(element, crop, 'profile-picture.jpg');
		}
	};

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		canvas.width = crop.width;
		canvas.height = crop.height;

		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height,
		);

		return new Promise(resolve => {
			canvas.toBlob(blob => {
				if (!blob) {
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;

				setBlob(blob);

				window.URL.revokeObjectURL(fileUrl);
				fileUrl = window.URL.createObjectURL(blob);
				resolve(fileUrl);
			}, 'image/jpeg');
		});
	};

	const handleCancel = () => {
		setSrc(null);
		setFilename(null);
		setCroppedImageUrl(null);
		setCrop({ unit: '%', width: 100, aspect: 16 / 16 });
		setBlob(null);

		fileUrl = null;
		element = null;
	};

	const handleSubmit = () => {
		const data = new FormData();
		data.append(`image`, blob);
		submit(data);
		handleCancel();
	};

	return (
		<>
			<h1>
				{`${user.firstName} ${user.lastName}`}
				<br />
				<div>Afbeelding</div>
			</h1>

			<Row>
				<Col lg={8} md={7} className='upload'>
					<label
						htmlFor='picture'
						className='btn btn-primary btn-block'>
						<span id='uploadIcon'>
							<FontAwesomeIcon icon={faUpload} />
						</span>
						<span id='cameraIcon'>
							<FontAwesomeIcon icon={faCamera} />
						</span>
						{filename ?? 'Kies een afbeelding'}
					</label>

					<input
						type='file'
						id='picture'
						accept='image/*'
						onChange={onSelectFile}
					/>

					{src ? (
						<div>
							<ReactCrop
								src={src}
								crop={crop}
								ruleOfThirds
								keepSelection
								onImageLoaded={onImageLoaded}
								onComplete={onCropComplete}
								onChange={onCropChange}
							/>
						</div>
					) : null}
				</Col>

				<Col lg={4} md={5}>
					<div className='account-sidebar'>
						{!croppedImageUrl ? (
							<div className='image'>
								{picture ? (
									<Button
										buttonStyle='primary'
										type='button'
										buttonSize='large'
										className='btn-danger'
										onClick={() => dispatch(removeImage())}>
										<FontAwesomeIcon icon={faTrash} />
									</Button>
								) : null}

								<img
									onError={e => defaultSrc(e, 'user')}
									src={`data:image/eng;base64,${image}`}
									alt={`${user.firstName} ${user.lastName}`}
								/>
							</div>
						) : (
							<div>
								<div className='btn-group full-flex'>
									<Button
										buttonStyle='secondary'
										label='Annuleren'
										onClick={handleCancel}
									/>
									<Button
										buttonStyle='primary'
										label='Bewaren'
										onClick={handleSubmit}
									/>
								</div>

								<div className='image'>
									<img
										alt='Crop'
										style={{ maxWidth: '100%' }}
										src={croppedImageUrl}
									/>
								</div>
							</div>
						)}
					</div>
				</Col>
			</Row>
		</>
	);
};

export default Picture;
