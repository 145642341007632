import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({
	type,
	label,
	buttonStyle,
	buttonSize,
	styles,
	block,
	className,
	disabled,
	onClick,
	children,
}) => {
	let string = ['btn'];
	buttonStyle && string.push(`btn-${buttonStyle}`);
	buttonSize && string.push(`btn-${buttonSize}`);
	block && string.push('btn-block');
	className && string.push(className);

	return (
		<button
			type={type}
			className={string.join(' ')}
			style={styles}
			disabled={disabled}
			onClick={onClick}>
			{label}
			{children}
		</button>
	);
};

Button.defaultProps = {
	type: 'button',
	styles: {},
	block: false,
	disabled: false,
};

Button.propTypes = {
	type: PropTypes.string,
	label: PropTypes.any,
	buttonStyle: PropTypes.string,
	buttonSize: PropTypes.string,
	styles: PropTypes.object,
	block: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	children: PropTypes.any,
};

export default Button;
