import PropTypes from 'prop-types';

import { Col } from 'components/Grid/Grid';

export const GroupFormat = ({ label, name, direction, children }) => (
	<>
		{label ? (
			<Col lg={12}>
				<label htmlFor={name}>{label}</label>
			</Col>
		) : null}
		{direction === 'horizontal' ? (
			children.map((child, i) => {
				const breakpoint =
					children.length > 3 ? 4 : 12 / children.length;

				return (
					<Col md={breakpoint} key={i}>
						{child}
					</Col>
				);
			})
		) : (
			<Col lg={12}>{children}</Col>
		)}
	</>
);

GroupFormat.propTypes = {
	children: PropTypes.any.isRequired,
	direction: PropTypes.string.isRequired,
	label: PropTypes.string,
	name: PropTypes.string,
};

export default GroupFormat;
