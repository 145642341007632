import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'assets/images/logo-up.png';
import { formExtractValuesHelpers } from 'core/helpers/form.helpers';
import { AuthForgotPassFormData } from 'core/services/data/form/auth.forgot.pass.form.data';
import { resetUser } from 'core/store/authentication/auth.actions';

import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import { Col } from 'components/Grid/Col/Col';
import { Container } from 'components/Grid/Container/Container';
import { Row } from 'components/Grid/Row/Row';
import Toasts from 'components/Snackbar/Snackbar';

const ForgotPass = () => {
	const dispatch = useDispatch();
	const [form, setForm] = useState(AuthForgotPassFormData);
	const navigate = useNavigate();

	const requestPassword = () => {
		const values = formExtractValuesHelpers(form);
		dispatch(resetUser(values, navigate));
	};

	return (
		<Container>
			<Row style={{ height: '100vh', paddingTop: 0 }}>
				<Col xl={7} lg={8} md={9} sm={10} xs={11} className='login'>
					<img
						src={logo}
						alt='UniPartners, united around values'
						className='login__logo'
					/>

					<Form form={form} updateForm={setForm} />

					<div className='btn-group' style={{ marginTop: 20 }}>
						<Button
							type='submit'
							label='Login'
							buttonStyle='primary'
							onClick={requestPassword}
							disabled={!form.valid}
						/>

						<Link
							to='/login'
							className='btn btn-link'
							style={{ padding: '8px 25px' }}>
							Inloggen
						</Link>
					</div>
				</Col>
			</Row>

			<Toasts />
		</Container>
	);
};

export default ForgotPass;
