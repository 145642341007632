import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';

const ConfigureStore = () => {
	const composeEnhancers =
		(typeof window !== 'undefined' &&
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE) ||
		compose;

	if (process.env.NODE_ENV === 'development') {
		return createStore(
			rootReducer,
			{},
			composeEnhancers(applyMiddleware(thunk)),
		);
	}

	return createStore(rootReducer, {}, applyMiddleware(thunk));
};

export default ConfigureStore;
