import { toShortDate } from 'core/helpers/date.helpers';
import PropTypes from 'prop-types';

import Attachments from 'components/Attachments/Attachments';
import BackButton from 'components/BackButton/BackButton';
import Carrousel from 'components/Carrousel/Carrousel';
import { Col, Row } from 'components/Grid/Grid';
import ItemOverview from 'components/ItemOverview/ItemOverview';
import Subscription from 'components/Subscription/Subscription';

import './ItemDetail.scss';

const ItemDetail = ({
	obj,
	objType,
	title,
	body,
	media,
	attachments,
	links,
	coce,
	tags,
	author,
	publishDate,
	instructor,
	organisors,
	days,
	location,
}) => {
	return (
		<Row>
			<Col xsOrder={1} md={12}>
				{media && media.length !== 0 ? (
					<Carrousel objMedia={media} />
				) : null}

				<div className='author'>
					Geplaatst door {author.firstName} {author.lastName} op{' '}
					{toShortDate(publishDate)}
				</div>
			</Col>

			<Col lg={8} xl={9} xsOrder={3} lgOrder={2}>
				<h1>{title}</h1>
				<div
					className='item-body'
					dangerouslySetInnerHTML={{ __html: body }}
				/>

				{(tags && tags.length > 0) || coce ? (
					<div className='tags'>
						<ul>
							{coce ? <li>#{coce}</li> : null}
							{tags && tags.length > 0
								? tags.map((tag, i) => <li key={i}>#{tag}</li>)
								: null}
						</ul>
					</div>
				) : null}
			</Col>

			<Col lg={4} xl={3} xsOrder={2} lgOrder={3}>
				<div className='detail-sidebar'>
					<Subscription author={author} obj={obj} objType={objType} />

					{location ||
					(days && days.length > 0) ||
					instructor ||
					(organisors && organisors.length > 0) ? (
						<ItemOverview
							location={location}
							days={days}
							instructor={instructor}
							organisers={organisors}
						/>
					) : null}

					{(attachments && attachments.length > 0) ||
					(links && links.length > 0) ? (
						<Attachments attachments={attachments} links={links} />
					) : null}

					<BackButton />
				</div>
			</Col>
		</Row>
	);
};

ItemDetail.propTypes = {
	obj: PropTypes.object.isRequired,
	objType: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
	media: PropTypes.array,
	attachments: PropTypes.array,
	links: PropTypes.array,
	coce: PropTypes.string,
	tags: PropTypes.array,
	author: PropTypes.object.isRequired,
	publishDate: PropTypes.string,
	location: PropTypes.string,
	days: PropTypes.array,
	instructor: PropTypes.object,
	organisers: PropTypes.array,
};

export default ItemDetail;
