import React from 'react';
import PropTypes from 'prop-types';

export const Col = props => {
	let string = [];
	props.xs && string.push('col-xs-' + props.xs);
	props.sm && string.push('col-sm-' + props.sm);
	props.md && string.push('col-md-' + props.md);
	props.lg && string.push('col-lg-' + props.lg);
	props.xl && string.push('col-xl-' + props.xl);
	props.xsOrder && string.push('order-xs-' + props.xsOrder);
	props.smOrder && string.push('order-sm-' + props.smOrder);
	props.mdOrder && string.push('order-md-' + props.mdOrder);
	props.lgOrder && string.push('order-lg-' + props.lgOrder);
	props.xlOrder && string.push('order-xl-' + props.xlOrder);

	props.xsHide && string.push('d-sx-none');
	props.smHide && string.push('d-sm-none');
	props.mdHide && string.push('d-md-none');
	props.lgHide && string.push('d-lg-none');
	props.xlHide && string.push('d-xl-none');

	props.className && string.push(props.className);

	return (
		<div className={string.join(' ')} style={props.style}>
			{props.children}
		</div>
	);
};

Col.propTypes = {
	xs: PropTypes.number,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	xsOrder: PropTypes.number,
	smOrder: PropTypes.number,
	mdOrder: PropTypes.number,
	lgOrder: PropTypes.number,
	xlOrder: PropTypes.number,
	xsHide: PropTypes.bool,
	smHide: PropTypes.bool,
	mdHide: PropTypes.bool,
	lgHide: PropTypes.bool,
	xlHide: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.object,
};
