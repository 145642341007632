import { useNavigate } from 'react-router-dom';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/Button/Button';

const BackButton = () => {
	const navigate = useNavigate();

	return (
		<div className='right' style={{ marginTop: '20px' }}>
			<Button
				type='button'
				buttonStyle='link'
				onClick={() => navigate(-1)}>
				<FontAwesomeIcon icon={faLongArrowAltLeft} /> Terug naar
				overzicht
			</Button>
		</div>
	);
};

export default BackButton;
