import PropTypes from 'prop-types';

import BackButton from 'components/BackButton/BackButton';

import './EmptyComponent.scss';

const EmptyComponent = ({ content, goBack }) => {
	return (
		<>
			<div className='no-content'>{content}</div>

			{goBack ? <BackButton /> : null}
		</>
	);
};

EmptyComponent.defaultValues = {
	goBack: false,
};

EmptyComponent.propTypes = {
	content: PropTypes.string.isRequired,
	goBack: PropTypes.bool,
};

export default EmptyComponent;
