import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadQuestions } from 'core/store/application/application.actions';

import Button from 'components/Button/Button';
import InputTextarea from 'components/Form/formInput/InputTextarea';
import { Col } from 'components/Grid/Col/Col';
import { Row } from 'components/Grid/Grid';
import Loading from 'components/Loading/Loading';

const Interview = ({ user, answers, submit }) => {
	const dispatch = useDispatch();
	const { questions, questionsLoading } = useSelector(
		state => state.applicationReducer,
	);
	const [value, setValue] = useState(answers);

	const handleChange = e => {
		setValue(
			questions.map(question => {
				const answer = value.find(x => x.questionId === question.id);

				if (question.id !== parseInt(e.target.name))
					return {
						questionId: question.id,
						answer: answer ? answer.answer : '',
					};

				return {
					...answer,
					questionId: question.id,
					answer: e.target.value,
				};
			}),
		);
	};

	const cancelForm = () => {
		setValue(answers);
	};

	const handleSubmit = () => {
		submit({
			interview: questions.map(question => {
				const answer = value?.find(x => x.questionId === question.id);

				return {
					questionId: question.id,
					answer: answer ? answer.answer : '',
				};
			}),
		});

		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		dispatch(loadQuestions());
	}, [dispatch]);

	return (
		<>
			<h1>
				{`${user.firstName} ${user.lastName}`}
				<br />
				<div>Interview</div>
			</h1>

			{questionsLoading ? (
				<Loading />
			) : (
				<>
					<Row>
						{questions.map(question => {
							const answer = value.find(
								x => x.questionId === question.id,
							);

							return (
								<Col md={6} key={question.id}>
									<InputTextarea
										name={`${question.id}`}
										id={`${question.id}`}
										label={question.body}
										rows={4}
										value={answer ? answer.answer : ''}
										required={false}
										onChange={handleChange}
									/>
								</Col>
							);
						})}
					</Row>

					<div className='right'>
						<div className='btn-group right'>
							<Button
								buttonStyle='secondary'
								label='Annuleren'
								onClick={cancelForm}
							/>
							<Button
								buttonStyle='primary'
								label='Bewaren'
								onClick={handleSubmit}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Interview;
