import { types } from '../action.constants';

const initialState = {
	user: null,
	userLoading: false,
	loggedIn: false,
	token: null,
	role: null,
	permissions: [],
	userErrors: [],
};

const loginUser = (state, { user, token, userLoading }) => ({
	...state,
	user,
	token,
	loggedIn: token !== null,
	userLoading,
});

const logoutUser = () => ({
	user: null,
	userLoading: false,
	loggedIn: false,
	token: null,
	role: null,
	permissions: [],
	userErrors: [],
});

const loadUser = (state, { user, userLoading }) => ({
	...state,
	user,
	userLoading,
});

const updateUser = (state, { user }) => ({
	...state,
	user,
});

export const authReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.USER_LOGIN:
			return loginUser(state, payload);
		case types.USER_LOGOUT:
			return logoutUser();
		case types.USER_LOAD:
			return loadUser(state, payload);
		case types.USER_UPDATE:
			return updateUser(state, payload);
		default:
			return state;
	}
};
