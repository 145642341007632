import {
	faFile,
	faFileAlt,
	faFileAudio,
	faFileExcel,
	faFileImage,
	faFilePdf,
	faFilePowerpoint,
	faFileVideo,
	faFileWord,
} from '@fortawesome/free-regular-svg-icons';

export const getFileIcon = mime => {
	let icon = '';
	switch (mime) {
		case 'text/plain':
			icon = faFileAlt;
			break;
		case 'application/xls':
		case 'application/vnd.ms-excel':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			icon = faFileExcel;
			break;
		case 'application/msword':
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			icon = faFileWord;
			break;
		case 'application/vnd.ms-powerpoint':
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			icon = faFilePowerpoint;
			break;
		case 'application/pdf':
			icon = faFilePdf;
			break;
		case 'audio/mpeg':
			icon = faFileAudio;
			break;
		case 'image/jpeg':
		case 'image/png':
		case 'image/bmp':
		case 'image/gif':
			icon = faFileImage;
			break;
		case 'video/mp4':
		case 'video/wav':
		case 'video/wave':
		case 'video/quicktime':
			icon = faFileVideo;
			break;
		default:
			icon = faFile;
	}

	return icon;
};
