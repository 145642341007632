import { array, func, string } from 'prop-types';

import Button from 'components/Button/Button';

import './PillsBar.scss';

const PillsBar = ({ tabs, selectedTab, setSelectedTab }) => {
	return (
		<div className='pills-bar' style={{ marginTop: '20px' }}>
			{tabs.map((tab, i) => {
				return (
					<Button
						buttonStyle='secondary'
						buttonSize='small'
						className={tab.name === selectedTab ? 'active' : ''}
						onClick={() => setSelectedTab(tab.name)}
						key={i}>
						{tab.label}
					</Button>
				);
			})}
		</div>
	);
};

PillsBar.propTypes = {
	tabs: array.isRequired,
	selectedTab: string.isRequired,
	setSelectedTab: func.isRequired,
};

export default PillsBar;
