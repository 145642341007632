export const breadSizeHelper = str => {
	const temp = {
		small_white: 'Klein wit broodje',
		large_white: 'Groot wit broodje',
		small_dark: 'Klein bruin broodje',
		large_dark: 'Groot bruin broodje',
	};

	return temp[str];
};
