import Slider from 'react-slick';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultSrc } from 'core/helpers/image.helpers';
import PropTypes from 'prop-types';

import Loading from 'components/Loading/Loading';

import { useImage } from '../../core/hooks/useImage/useImage';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carrousel.scss';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	prevArrow: <PrevArrow />,
	nextArrow: <NextArrow />,
};

const Carrousel = ({ objMedia }) => {
	const image = useImage(objMedia[0].filename);

	return (
		<Slider {...settings}>
			{image ? (
				<div>
					{image.loading ? (
						<Loading />
					) : (
						<img
							src={`data:image/eng;base64,${image}`}
							alt={objMedia[0].description}
							style={{ width: '200px' }}
							onError={defaultSrc}
						/>
					)}
				</div>
			) : null}
		</Slider>
	);
};

function PrevArrow(props) {
	const { onClick } = props;
	return (
		<div className='slick-arrows prev' onClick={onClick}>
			<FontAwesomeIcon icon={faChevronLeft} />
		</div>
	);
}

function NextArrow(props) {
	const { onClick } = props;
	return (
		<div className='slick-arrows next' onClick={onClick}>
			<FontAwesomeIcon icon={faChevronRight} />
		</div>
	);
}

Carrousel.propTypes = {
	objMedia: PropTypes.array.isRequired,
};

export default Carrousel;
