import React from 'react';
import PropTypes from 'prop-types';

const InputTextarea = ({
	id,
	name,
	label,
	placeholder,
	description,
	value,
	valid,
	onChange,
	onBlur,
	error,
	className,
	styles,
	required,
	readOnly,
	autocomplete,
	disabled,
	autoFocus,
	rows,
}) => (
	<div
		className={`form-group${className ? ` ${className}` : ''}`}
		style={styles}>
		{label ? (
			<label htmlFor={id}>
				{label} {required ? <code>*</code> : null}
			</label>
		) : null}

		<textarea
			name={name}
			id={id}
			value={value ?? ''}
			placeholder={
				placeholder + (placeholder && required && !label ? ' *' : '')
			}
			className={`form-control${valid === '' ? '' : valid ? ' is-valid' : ' is-invalid'}`}
			onChange={onChange}
			onBlur={onBlur}
			rows={rows}
			autoFocus={autoFocus}
			onFocus={function (e) {
				const val = e.target.value;
				e.target.value = '';
				e.target.value = val;
			}}
			autoComplete={autocomplete ? 'on' : 'off'}
			readOnly={readOnly}
			disabled={disabled}
		/>
		<div className='description'>{description}</div>
		<small className='invalid-feedback'>{error}</small>
	</div>
);

InputTextarea.defaultProps = {
	label: '',
	placeholder: '',
	value: '',
	rows: 1,
	error: '',
	valid: true,
	className: '',
	styles: {},
	required: false,
	readOnly: false,
	autocomplete: false,
	autoFocus: false,
	disabled: false,
};

InputTextarea.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	description: PropTypes.string,
	value: PropTypes.string,
	rows: PropTypes.number,
	error: PropTypes.string,
	valid: PropTypes.bool,
	className: PropTypes.string,
	styles: PropTypes.object,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	autocomplete: PropTypes.bool,
	disabled: PropTypes.bool,
	autoFocus: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
};

export default InputTextarea;
