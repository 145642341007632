import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadTraining } from 'core/store/training/training.actions';

import EmptyComponent from 'components/EmptyComponent/EmptyComponent';
import ItemDetail from 'components/ItemDetail/ItemDetail';
import Loading from 'components/Loading/Loading';

const TrainingDetail = () => {
	const dispatch = useDispatch();
	const { slug } = useParams();
	const { training, trainingsLoading } = useSelector(
		state => state.trainingReducer,
	);

	useEffect(() => {
		dispatch(loadTraining(slug));
	}, [dispatch]);

	return (
		<div>
			{trainingsLoading ? (
				<Loading />
			) : !training ? (
				<EmptyComponent
					content='Opleidingen niet gevonden'
					goBack={true}
				/>
			) : (
				<ItemDetail
					id={training.id}
					title={training.name}
					slug={training.slug}
					body={training.description}
					media={training.media}
					attachments={training.attachments}
					links={training.links}
					coce={training.coce}
					tags={training.tags}
					author={training.creator}
					publishDate={training.publishedAt}
					obj={training}
					objType='trainings'
					instructor={training.instructor}
					organisors={training.organisors}
					days={training.days}
					location={training.location}
				/>
			)}
		</div>
	);
};

export default TrainingDetail;
