import axios from 'axios';
import snackbarFactories from 'core/factories/snackbar.factories';
import { errorHelper } from 'core/helpers/message.helpers/error.message.helpers';

import { API_URL, APP_KEY, types } from '../action.constants';

export const downloadAttachment = file => (dispatch, getState) => {
	const state = getState();
	const { token } = state.authReducer;
	const { attachments } = state.attachmentReducer;

	const obj = attachments.find(x => x.id === file.id);
	if (obj) {
		download(obj, file);
	} else {
		dispatch({
			type: types.ATTACHMENTS_LOAD,
			payload: { blob: null, id: file.id },
		});
		const header = {
			headers: {
				Authorization: 'Bearer ' + token,
				'x-up-app': APP_KEY,
			},
		};
		axios
			.get(`${API_URL}/data/${file.filename}`, header)
			.then(data => {
				dispatch({
					type: types.ATTACHMENTS_LOAD,
					payload: { attachment: data, id: file.id },
				});
				download(data, file);
			})
			.catch(error => {
				const response = error.response;
				const message = errorHelper(
					'attachment',
					'load',
					response,
					error.response.status,
				);
				dispatch({
					type: types.TOAST_ADD,
					payload: {
						toast: snackbarFactories({
							text: message,
							type: 'error',
						}),
					},
				});
			});
	}
};

const download = (data, file) => {
	const blob = new Blob([data], { type: file.mime });
	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = file.description;
	link.click();
};
