import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PublicRoute = ({ children, loggedIn, useFrom }) => {
	let location = useLocation();

	return loggedIn ? (
		useFrom ? (
			<Navigate to='/' state={{ from: location }} />
		) : (
			<Navigate to='/' />
		)
	) : (
		children
	);
};

export default PublicRoute;
