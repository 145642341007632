import axios from 'axios';
import snackbarFactories from 'core/factories/snackbar.factories';
import { errorHelper } from 'core/helpers/message.helpers/error.message.helpers';

import { API_URL, APP_KEY, types } from '../action.constants';

export const loadTrainings = () => (dispatch, getState) => {
	dispatch({
		type: types.TRAININGS_LOAD,
		payload: { trainings: [], trainingsLoading: true },
	});
	const state = getState();
	const { token } = state.authReducer;

	const header = {
		headers: { Authorization: `Bearer ${token}`, 'x-up-app': APP_KEY },
	};
	axios
		.get(`${API_URL}/trainings`, header)
		.then(({ data: { data } }) => {
			dispatch({
				type: types.TRAININGS_LOAD,
				payload: { trainings: data, trainingsLoading: false },
			});
		})
		.catch(error => {
			const response = error.response;
			const message = errorHelper(
				'trainings',
				'read',
				response,
				error.response.status,
			);
			dispatch({
				type: types.TOAST_ADD,
				payload: {
					toast: snackbarFactories({ text: message, type: 'error' }),
				},
			});
		});
};

export const loadTraining = slug => (dispatch, getState) => {
	dispatch({
		type: types.TRAINING_LOAD,
		payload: { training: null, trainingsLoading: true },
	});
	const state = getState();
	const { token } = state.authReducer;
	const { trainings } = state.trainingReducer;

	const training = trainings.find(x => x.slug === slug);
	if (training && training.isComplete) {
		dispatch({
			type: types.TRAINING_LOAD,
			payload: { training, trainingsLoading: false },
		});
	} else {
		const header = {
			headers: {
				Authorization: `Bearer ${token}`,
				'x-up-app': APP_KEY,
			},
		};
		axios
			.get(`${API_URL}/trainings/${slug}`, header)
			.then(({ data: { data } }) => {
				dispatch({
					type: types.TRAINING_LOAD,
					payload: { training: data, trainingsLoading: false },
				});
			})
			.catch(error => {
				const response = error.response;
				const message = errorHelper(
					'training',
					'read',
					response,
					error.response.status,
				);
				dispatch({
					type: types.TOAST_ADD,
					payload: {
						toast: snackbarFactories({
							text: message,
							type: 'error',
						}),
					},
				});
			});
	}
};

export const sendForm = (type, id, body) => (dispatch, getState) => {
	const state = getState();
	const { token } = state.authReducer;

	const header = {
		headers: { Authorization: `Bearer ${token}`, 'x-up-app': APP_KEY },
	};
	axios
		.post(`${API_URL}/forms`, body, header)
		.then(({ data }) => {
			dispatch({
				type: types.TRAINING_ORDER_LOAD,
				payload: { order: data, id },
			});
			dispatch({
				type: types.TOAST_ADD,
				payload: {
					toast: snackbarFactories({
						text: 'Je formulier werd verzonden.',
						type: 'success',
					}),
				},
			});
		})
		.catch(error => {
			const response = error.response;
			const message = errorHelper(
				'form',
				'post',
				response,
				error.response.status,
			);
			dispatch({
				type: types.TOAST_ADD,
				payload: {
					toast: snackbarFactories({ text: message, type: 'error' }),
				},
			});
		});
};
