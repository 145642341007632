import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { API_URL, APP_KEY } from '../../store/action.constants';

export const useImage = imageToken => {
	const [imageSrc, setImageSrc] = useState('');
	const { token } = useSelector(state => state.authReducer);

	useEffect(() => {
		const [imageId, imageType] = (imageToken + '' || '')
			.split('/')
			.slice(-1)[0]
			.split('_');
		const data = {
			type: imageType || 'original',
			images: [imageId],
		};

		const fetchImage = async () => {
			const header = {
				headers: {
					'x-up-app': APP_KEY,
					Authorization: 'Bearer ' + token,
				},
			};

			axios
				.post(`${API_URL}/data`, data, header)
				.then(
					({
						data: {
							data: [image],
						},
					}) => {
						setImageSrc(image || '');
					},
				)
				.catch(error => {
					console.log(error);
				});
		};
		fetchImage();
	}, [imageToken]);

	return imageSrc;
};
