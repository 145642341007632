import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toLongDate } from 'core/helpers/date.helpers';
import { orderData } from 'core/services/data/order.data';
// eslint-disable-next-line no-unused-vars
import { sendForm } from 'core/store/training/training.actions';

import { InputSelect } from 'components/Form/formInput';
import InputCheckbox from 'components/Form/formInput/InputCheckbox';
import InputTextarea from 'components/Form/formInput/InputTextarea';
import { Col, Row } from 'components/Grid/Grid';

// eslint-disable-next-line no-unused-vars
const Form = forwardRef(({ obj, objType, toppings, setFormValid }, ref) => {
	// eslint-disable-next-line no-unused-vars
	const dispatch = useDispatch();
	const [order, setOrder] = useState(() =>
		orderData(obj.days, obj.order, toppings),
	);
	const { user } = useSelector(state => state.authReducer);

	const handleOrderCheckbox = (date, name) => {
		setOrder({
			...order,
			days: order.days.map(x => {
				if (x.date !== date) return x;

				return {
					...x,
					[name]: !x[name],
					sizeValid:
						x.size !== null && x.size !== undefined
							? true
							: x.order,
					toppingValid:
						x.topping !== null && x.topping !== undefined
							? true
							: x.order,
				};
			}),
		});
	};

	const handleOrderTextarea = e => {
		const { value, name } = e.target;
		setOrder({
			...order,
			[name]: value,
		});
	};

	const handleOrderTouched = (date, name) => {
		setOrder({
			...order,
			days: order.days.map(x => {
				if (x.date !== date) return x;

				return {
					...x,
					[`${name}Touched`]: true,
				};
			}),
		});
	};

	const handleOrderSelect = (selectedOption, date, name) => {
		setOrder({
			...order,
			days: order.days.map(x => {
				if (x.date !== date) return x;

				return {
					...x,
					[name]: selectedOption,
					[`${name}Valid`]:
						selectedOption !== null && selectedOption !== undefined,
					extra:
						selectedOption && selectedOption.extra
							? x.extra
							: false,
				};
			}),
		});
	};

	const validateForm = () => {
		const invalid = order.days.filter(
			x => x.sizeValid === false || x.toppingValid === false,
		);
		setFormValid(invalid.length === 0);
	};

	useImperativeHandle(ref, () => ({
		handleSubmit() {
			const body = {
				trainingId: obj.id,
				userId: user.id,
				results: order.days
					.filter(x => x.acceptOrders)
					.map(({ order, date, size, topping, extra }) => {
						return {
							date: date,
							bread: order,
							...(order
								? {
										size: size.value,
										extra: extra ?? false,
										topping: topping.value,
									}
								: {}),
						};
					}),
				remark: order.remark,
			};

			dispatch(sendForm(objType, obj.id, body, order));
		},
	}));

	useEffect(() => {
		validateForm();
	});

	return (
		<Row>
			{order ? (
				<>
					{order.days.map((day, i) => {
						console.log(day);
						if (day.acceptOrders) {
							return (
								<Col lg={3} md={4} sm={6} key={i}>
									<p>
										<b>{toLongDate(day.date)}</b>
									</p>
									<InputCheckbox
										id={`order_${i}`}
										name={`order_${i}`}
										checked={day.order}
										label='Bestel broodje'
										onChange={() =>
											handleOrderCheckbox(
												day.date,
												'order',
											)
										}
									/>
									{day.order ? (
										<>
											<InputSelect
												id={`size_${i}`}
												options={[
													{
														value: 'small_white',
														label: 'Klein wit broodje',
													},
													{
														value: 'large_white',
														label: 'Groot wit broodje',
													},
													{
														value: 'small_dark',
														label: 'Klein bruin broodje',
													},
													{
														value: 'large_dark',
														label: 'Groot bruin broodje',
													},
												]}
												onChange={e =>
													handleOrderSelect(
														e,
														day.date,
														'size',
													)
												}
												value={day.size}
												label='Broodje'
												placeholder='Selecteer je broodje'
												required={true}
												isMulti={false}
												error='Broodje is een verplicht veld'
												valid={
													day.sizeTouched
														? day.sizeValid
														: true
												}
												onBlur={() =>
													handleOrderTouched(
														day.date,
														'size',
													)
												}
											/>
											<InputSelect
												id={`topping_${i}`}
												options={toppings.map(x => {
													return {
														value: x.id,
														label: x.topping,
														extra: x.hasOptions,
														desc: x.description,
													};
												})}
												onChange={e =>
													handleOrderSelect(
														e,
														day.date,
														'topping',
													)
												}
												value={day.topping}
												label='Beleg'
												placeholder='Selecteer je beleg'
												description={
													day.topping
														? day.topping.desc
														: null
												}
												required={true}
												isMulti={false}
												error='Beleg is een verplicht veld'
												valid={
													day.toppingTouched
														? day.toppingValid
														: true
												}
												onBlur={() =>
													handleOrderTouched(
														day.date,
														'topping',
													)
												}
											/>

											{day.topping &&
											day.topping.extra ? (
												<InputCheckbox
													id={`extra_${i}`}
													name={`extra_${i}`}
													checked={day.extra}
													label='Smos'
													onChange={() =>
														handleOrderCheckbox(
															day.date,
															'extra',
														)
													}
												/>
											) : null}
										</>
									) : null}
								</Col>
							);
						} else {
							return (
								<Col lg={3} md={4} sm={6} key={i}>
									<p>
										<b>{toLongDate(day.date)}</b>
									</p>
									<p>{day.reason}</p>
								</Col>
							);
						}
					})}

					<Col md={12}>
						<InputTextarea
							id='remark'
							name='remark'
							onChange={handleOrderTextarea}
							value={order.remark}
							label='Opmerking'
							placeholder='Geef hier je speciale wensen of opmerkingen door'
							required={false}
							valid={true}
							rows={5}
						/>
					</Col>
				</>
			) : null}
		</Row>
	);
});

export default Form;
