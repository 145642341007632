import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/logo-up.png';

import { Container } from 'components/Grid/Grid';

import MainNavigation from './MainNavigation/MainNavigation';

import './Navigation.scss';

const Navigation = () => {
	return (
		<header className='header'>
			<Container>
				<Link to='/home'>
					<img
						src={Logo}
						alt='UniPartners, united around values'
						className='logo'
					/>
				</Link>

				<nav>
					<MainNavigation />
				</nav>
			</Container>
		</header>
	);
};

export default Navigation;
