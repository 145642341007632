export const toLongDate = timestamp => {
	let options = { year: 'numeric', month: 'long', day: 'numeric' };
	return new Date(timestamp).toLocaleDateString(`nl-BE`, options);
};

export const toShortDate = (timestamp, limit = false) => {
	let options;

	if (limit) options = { month: '2-digit', day: 'numeric' };
	else options = { year: 'numeric', month: '2-digit', day: 'numeric' };

	return new Date(timestamp).toLocaleDateString(`nl-BE`, options);
};
