import { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useDarkMode } from 'core/hooks/useDarkMode/useDarkMode';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';

import './Modal.scss';

const modalRoot = document.getElementById('modal-root');
const element = document.createElement('div');

const Modal = ({
	show,
	close,
	large,
	medium,
	children,
	title,
	className,
	style,
	actions,
}) => {
	const [theme] = useDarkMode();

	const renderModal = () => {
		if (!show) return;

		return (
			<div className='modal'>
				<div
					className={`modal__content${large ? ' large' : ''}${medium ? ' medium' : ''}${className ? ` ${className}` : ''}`}
					style={style}>
					<h2>{title ? title : 'Modal window'}</h2>
					<div className='modal__body'>{children}</div>
					<div className='modal__actions'>
						<div className='btn-group'>
							<Button
								buttonSize='small'
								buttonStyle='secondary'
								onClick={close}
								label='Annuleren'
							/>

							{actions.map((x, i) => (
								<Button
									key={i}
									buttonSize='small'
									buttonStyle='primary'
									className={x.className}
									onClick={x.action}
									styles={x.styles}
									label={x.label}
									disabled={!x.disabled}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		element.className = `theme--${theme}`;
		modalRoot.appendChild(element);

		return function cleanup() {
			modalRoot.removeChild(element);
		};
	});

	return ReactDOM.createPortal(renderModal(), element);
};

Modal.propTypes = {
	close: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	large: PropTypes.bool,
	medium: PropTypes.bool,
	children: PropTypes.any.isRequired,
	title: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.object,
	actions: PropTypes.array,
};

Modal.defaultProps = {
	large: false,
	medium: false,
	actions: [],
};

export default Modal;
