import React from 'react';

import { Container } from 'components/Grid/Grid';
import { CrownBar, Navigation } from 'components/Header';
import Toasts from 'components/Snackbar/Snackbar';

import LayoutRouter from './LayoutRouter';

const Layout = () => {
	return (
		<>
			<CrownBar />
			<Navigation />

			<main>
				<Container>
					<LayoutRouter />
				</Container>
			</main>

			<Toasts />
		</>
	);
};

export default Layout;
