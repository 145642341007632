import { combineReducers } from 'redux';

import { applicationReducer } from './application/application.reducers';
import { attachmentReducer } from './attachment/attachment.reducer';
import { authReducer } from './authentication/auth.reducers';
import { snackbarReducer } from './snackbar/snackbar.reducers';
import { toppingReducer } from './topping/topping.reducers';
import { trainingReducer } from './training/training.reducers';

export default combineReducers({
	authReducer,
	applicationReducer,
	attachmentReducer,
	trainingReducer,
	toppingReducer,
	snackbarReducer,
});
