import React, { Component } from 'react';
import {
	faCheck,
	faExclamationTriangle,
	faFire,
	faInfoCircle,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, string } from 'prop-types';

import './Toast.scss';

class Toast extends Component {
	constructor(props) {
		super(props);

		this.removeRef = null;
		this.id = null;
	}

	shouldComponentUpdate() {
		return null;
	}

	componentDidMount() {
		const { autoDismiss } = this.props;

		if (autoDismiss) {
			const duration = 5000;
			// eslint-disable-next-line react/no-is-mounted
			this.id = setTimeout(() => this.removeRef(), duration);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.id);
	}

	render() {
		const { text, type, autoDismiss, onDismissClick } = this.props;

		let icon;
		switch (type) {
			case 'success':
				icon = faCheck;
				break;
			case 'error':
				icon = faFire;
				break;
			case 'warning':
				icon = faExclamationTriangle;
				break;
			case 'info':
				icon = faInfoCircle;
				break;
			default:
				icon = faInfoCircle;
				break;
		}

		const animation = autoDismiss
			? '5000ms linear 0s 1 normal running animation-timeout'
			: '';

		return (
			<div
				ref={() => {
					this.removeRef = onDismissClick;
				}}
				className={`toast__container ${type}`}>
				<div className='toast__notification'>
					<div className='toast__icon'>
						<div
							className='toast__countdown'
							style={{
								animation: animation,
								animationPlayState: 'running',
								opacity: 1,
							}}
						/>

						<FontAwesomeIcon icon={icon} />
					</div>

					<div className='toast__body'>{text}</div>

					<div className='toast__actions'>
						<FontAwesomeIcon
							icon={faTimes}
							onClick={onDismissClick}
						/>
						<span className='toast__dismiss'>sluit</span>
					</div>
				</div>
			</div>
		);
	}
}

Toast.propTypes = {
	text: string.isRequired,
	type: string.isRequired,
	autoDismiss: bool,
	onDismissClick: func.isRequired,
};

Toast.defaultProps = {
	autoDismiss: true,
};

export default Toast;
