import { useEffect, useState } from 'react';
import { FieldValidator } from 'core/helpers/form.helpers';

import Button from 'components/Button/Button';
import { InputField } from 'components/Form/formInput';
import InputCheckbox from 'components/Form/formInput/InputCheckbox';
import { Col } from 'components/Grid/Col/Col';
import { Row } from 'components/Grid/Row/Row';

let initialSocials = [
	{
		type: 'url',
		required: false,
		name: 'Facebook',
		value: '',
		valid: true,
		error: '',
	},
	{
		type: 'url',
		required: false,
		name: 'Instagram',
		value: '',
		valid: true,
		error: '',
	},
	{
		type: 'url',
		required: false,
		name: 'LinkedIn',
		value: '',
		valid: true,
		error: '',
	},
	{
		type: 'url',
		required: false,
		name: 'Twitter',
		value: '',
		valid: true,
		error: '',
	},
	{
		type: 'url',
		required: false,
		name: 'YouTube',
		value: '',
		valid: true,
		error: '',
	},
];

const Contact = ({
	user,
	userEmail,
	userSettings,
	userPhoneNumber,
	userSocials,
	submit,
}) => {
	const [email, setEmail] = useState({
		type: 'email',
		required: true,
		name: 'email',
		value: userEmail,
		valid: true,
		error: '',
		visible: userSettings.showEmail,
	});
	const [telephone, setTelephone] = useState({
		type: 'telephone',
		required: false,
		name: 'telephone',
		value: userPhoneNumber.number,
		valid: true,
		error: '',
		visible: userPhoneNumber.visible,
	});
	const [socials, setSocials] = useState(
		initialSocials.map(s => {
			const media = userSocials.find(x => x.type === s.name);
			if (!media) return s;

			return {
				...s,
				value: media.link,
			};
		}),
	);
	const [formValid, setFormValid] = useState(false);

	const handleSocialChange = e => {
		const { name, value } = e.target;

		setSocials(
			socials.map(social => {
				if (social.name !== name) return social;

				return {
					...social,
					...FieldValidator.validate(social, value),
					value,
				};
			}),
		);
	};

	const handleEmailChange = e => {
		const { name, value } = e.target;

		if (name === 'email_visible') {
			setEmail({
				...email,
				visible: !email.visible,
			});
		} else {
			setEmail({
				...email,
				...FieldValidator.validate(email, value),
				value,
			});
		}
	};

	const handleTelephoneChange = e => {
		const { name, value } = e.target;
		if (name === 'tel_visible') {
			setTelephone({
				...telephone,
				visible: !telephone.visible,
			});
		} else {
			setTelephone({
				...telephone,
				...FieldValidator.validate(telephone, value),
				value,
			});
		}
	};

	const validateForm = () => {
		setFormValid(
			email.valid &&
				telephone.valid &&
				socials.filter(x => !x.valid).length === 0,
		);
	};

	const cancelForm = () => {
		setEmail({
			type: 'email',
			required: true,
			name: 'email',
			value: userEmail,
			valid: true,
			error: '',
			visible: userSettings.showEmail,
		});
		setTelephone({
			type: 'telephone',
			required: false,
			name: 'telephone',
			value: userPhoneNumber.number,
			valid: true,
			error: '',
			visible: userPhoneNumber.visible,
		});
		setSocials(
			initialSocials.map(s => {
				const media = userSocials.find(x => x.type === s.name);
				if (!media) return s;

				return {
					...s,
					value: media.link,
				};
			}),
		);
	};

	const handleSubmit = () => {
		submit({
			socialMedia: socials.map(social => ({
				type: social.name.toLowerCase(),
				link: social.value,
			})),
			email: {
				value: email.value,
				visible: email.visible,
			},
			telephone: {
				value: telephone.value,
				visible: telephone.visible,
			},
		});
	};

	useEffect(() => {
		validateForm();
	}, [email, telephone, socials]);

	return (
		<>
			<h1>
				{`${user.firstName} ${user.lastName}`}
				<br />
				<div>Contactgegevens</div>
			</h1>

			<Row>
				<Col md={6} className='contact'>
					<h3>Jouw e-mailadres</h3>
					<InputField
						type={email.type}
						name={email.name}
						id={email.name}
						label='E-mailadres'
						value={email.value}
						description='Wanneer je dit e-mailadres wijzigt, zal u in de toekomst moeten inloggen met dit nieuwe e-mailadres.'
						required={email.required}
						valid={email.valid}
						error={email.error}
						onChange={handleEmailChange}
					/>
					<InputCheckbox
						id='email_visible'
						name='email_visible'
						checked={email.visible}
						label='Toon dit e-mailadres op je account'
						valid={true}
						error=''
						onChange={handleEmailChange}
					/>

					<h3>Jouw telefoonnummer</h3>
					<InputField
						type='text'
						name={telephone.name}
						id={telephone.name}
						label='Telefoonnummer'
						value={telephone.value}
						description='Voeg een telefoonnummer in zonder spaties. Enkel Belgische GSM-nummers worden aanvaard. Het nummer kan zowel met als zonder de +32 vermelding.'
						required={telephone.required}
						valid={telephone.valid}
						error={telephone.error}
						onChange={handleTelephoneChange}
					/>
					<InputCheckbox
						id='tel_visible'
						name='tel_visible'
						checked={telephone.visible}
						label='Toon dit nummer op je account'
						valid={true}
						error=''
						onChange={handleTelephoneChange}
					/>
				</Col>

				<Col md={6} className='socials'>
					<h3>Jouw sociale Media-accounts</h3>
					{socials.map((social, index) => (
						<InputField
							type={social.type}
							name={social.name}
							id={social.name}
							label={social.name}
							value={social.value}
							placeholder={`Jouw ${social.name} link`}
							required={social.required}
							valid={social.valid}
							error={social.error}
							onChange={handleSocialChange}
							key={index}
						/>
					))}
				</Col>

				<Col md={12} className='right'>
					<div className='btn-group right'>
						<Button
							buttonStyle='secondary'
							label='Annuleren'
							onClick={cancelForm}
						/>
						<Button
							buttonStyle='primary'
							label='Bewaren'
							disabled={!formValid}
							onClick={handleSubmit}
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default Contact;
