import snackbarFactories from 'core/factories/snackbar.factories';

import { types } from '../action.constants';

export const addToast = options => dispatch => {
	dispatch({ type: types.TOAST_ADD, payload: snackbarFactories(options) });
};

export const removeToast = id => dispatch => {
	dispatch({ type: types.TOAST_REMOVE, payload: { id } });
};
