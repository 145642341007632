import React, { Component } from 'react';
import {
	FieldValidator,
	FormValidator,
	getDefaultValue,
} from 'core/helpers/form.helpers';
import { INPUT_TYPES } from 'core/helpers/input.types.helper';
import PropTypes from 'prop-types';

import { Row } from 'components/Grid/Grid';

import { InputField } from './formInput';
import { GroupFormat } from './FormLayout';

import './Form.scss';

class Form extends Component {
	constructor(props) {
		super(props);

		this.generateForm = this.generateForm.bind(this);
		this.generateFormat = this.generateFormat.bind(this);
		this.generateValue = this.generateValue.bind(this);

		this.handleBlur = this.handleBlur.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.validateForm = this.validateForm.bind(this);
	}

	componentDidMount() {
		let { form } = this.props;
		// eslint-disable-next-line react/no-is-mounted
		this.generateForm(form);
	}

	generateForm = form => {
		form.fields = this.generateFormat(form);

		if (form.format[0].type === INPUT_TYPES.TAB) {
			/*form.tabSettings = TabSettings.generate(form)

            form.buttons = [
                {
                    label: "vorige",
                    action: () => this._setPrev(),
                    styles: {display: form.tabSettings.step === 1 ? 'none' : 'inline-block'},
                    disabled: true
                }, {
                    label: "volgende",
                    action: () => this._setNext(),
                    styles: {display: form.tabSettings.step === form.tabSettings.steps ? 'none' : 'inline-block'},
                    disabled: form.tabSettings.valid
                }
            ];*/
		}

		this.props.updateForm(form);
		this.validateForm();
	};

	generateFormat(form) {
		return form.fields.map(field => {
			return {
				...field,
				// eslint-disable-next-line react/no-is-mounted
				...this.generateValue(field, form),
			};
		});
	}

	generateValue(field) {
		let value = {
			value: field.value ? field.value : getDefaultValue(field),
			touched: field.touched ? field.touched : false,
		};

		if (field.files) {
			value = {
				...value,
				files: field.files,
			};
		}

		return { ...value, ...FieldValidator.validate(field, value.value) };
	}

	handleBlur = e => {
		const { name } = e.target;
		let { form } = this.props;

		form.fields = form.fields.map(field => {
			if (field.name !== name) return field;

			return {
				...field,
				touched: true,
			};
		});

		this.props.updateForm(form);
		this.validateForm();
	};

	handleInput(e) {
		let { value, name } = e.target;
		let { form } = this.props;

		form.fields = form.fields.map(field => {
			if (field.name !== name) return field;

			const valid = FieldValidator.validate(field, value);
			return {
				...field,
				value,
				...valid,
			};
		});

		// eslint-disable-next-line react/no-is-mounted
		this.generateForm(form);
	}

	validateForm() {
		let { form } = this.props;

		form = {
			...form,
			valid: FormValidator.validate(form),
		};

		this.props.updateForm(form);
	}

	render() {
		const { form } = this.props;

		return (
			<Row>
				{form.format.map((format, index) =>
					// eslint-disable-next-line react/no-is-mounted
					this.loadElement(format, index),
				)}
			</Row>
		);
	}

	loadElement = (element, index) => {
		const { form } = this.props;

		switch (element.type) {
			case 'col':
			case 'tab':
				break;

			case INPUT_TYPES.GROUP:
				return (
					<GroupFormat
						key={index}
						name={element.name}
						label={element.label}
						direction={element.direction}>
						{element.fields.map((field, elementIndex) => {
							field = form.fields.find(x => x.name === field);
							return this.loadElement(field, elementIndex);
						})}
					</GroupFormat>
				);

			default:
				return (
					<InputField
						key={index}
						type={element.type}
						id={element.id ? element.id : element.name}
						name={element.name}
						label={element.label}
						placeholder={element.placeholder}
						description={element.description}
						value={element.value}
						error={element.error}
						valid={element.touched ? element.valid : true}
						className={element.className}
						styles={element.styles}
						required={element.required}
						readOnly={element.readOnly}
						autocomplete={element.autocomplete}
						disabled={element.disabled}
						onChange={this.handleInput}
						onBlur={this.handleBlur}
					/>
				);
		}
	};
}

Form.propTypes = {
	form: PropTypes.object.isRequired,
	updateForm: PropTypes.func.isRequired,
};

export default Form;
