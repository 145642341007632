import axios from 'axios';
import snackbarFactories from 'core/factories/snackbar.factories';
import { errorHelper } from 'core/helpers/message.helpers/error.message.helpers';

import { API_URL, APP_KEY, types } from '../action.constants';

export const loadToppings = () => (dispatch, getState) => {
	dispatch({
		type: types.TOPPINGS_LOAD,
		payload: { toppings: [], toppingsLoading: true },
	});
	const state = getState();
	const { token } = state.authReducer;

	const header = {
		headers: { Authorization: `Bearer ${token}`, 'x-up-app': APP_KEY },
	};
	axios
		.get(`${API_URL}/toppings`, header)
		.then(({ data }) => {
			dispatch({
				type: types.TOPPINGS_LOAD,
				payload: { toppings: data, toppingsLoading: false },
			});
		})
		.catch(error => {
			const response = error.response;
			const message = errorHelper(
				'toppings',
				'load',
				response,
				error.response.status,
			);
			dispatch({
				type: types.TOAST_ADD,
				payload: {
					toast: snackbarFactories({ text: message, type: 'error' }),
				},
			});
		});
};
