import { Link } from 'react-router-dom';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
	faChalkboardTeacher,
	faMapPin,
	faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toLongDate } from 'core/helpers/date.helpers';
import PropTypes from 'prop-types';

import './ItemOverview.scss';

const ItemOverview = ({
	slug,
	name,
	location,
	days,
	instructor,
	organisers,
	logo,
}) => {
	return (
		<div className='overview-item'>
			{logo || name ? (
				<div className='header'>
					{logo ? <img src={logo} alt={name} /> : null}
					{name ? <h2 className='title'>{name}</h2> : null}
				</div>
			) : null}

			<div className='content'>
				{days.length > 0
					? days.length === 1
						? DateTime(days[0])
						: DateTimeArray(days)
					: null}

				{organisers && organisers.length > 0 ? (
					<div className='organisers'>
						<FontAwesomeIcon icon={faUserFriends} />
						<ul className='organiser'>
							{organisers.map((organiser, i) => (
								<li
									key={
										i
									}>{`${organiser.firstName} ${organiser.lastName}`}</li>
							))}
						</ul>
					</div>
				) : null}

				{instructor ? (
					<div className='instructor'>
						<FontAwesomeIcon icon={faChalkboardTeacher} />{' '}
						{`${instructor.firstName} ${instructor.lastName}`}
					</div>
				) : null}
				{location ? (
					<div className='location'>
						<FontAwesomeIcon icon={faMapPin} />{' '}
						<span
							dangerouslySetInnerHTML={{
								__html: location.replace(', ', '<br />'),
							}}
						/>
					</div>
				) : null}

				<div className='btn-group'>
					{slug ? (
						<Link
							to={`/opleidingen/${slug}`}
							className='btn btn-primary btn-small single'>
							Bekijk details
						</Link>
					) : null}
				</div>
			</div>
		</div>
	);
};

const DateTime = ({ date, startTime, endTime }) => {
	return (
		<div>
			<div className='date'>{toLongDate(date)}</div>
			<div className='time'>
				<FontAwesomeIcon icon={faClock} /> {startTime}{' '}
				{endTime && endTime !== startTime ? ' - ' + endTime : null}
			</div>
		</div>
	);
};

const DateTimeArray = days => {
	return (
		<div>
			{days.map((day, i) => {
				const { date, startTime, endTime } = day;

				return (
					<div key={i} className='date-array'>
						<div className='date'>{toLongDate(date)}</div>
						<div className='time'>
							<FontAwesomeIcon icon={faClock} /> {startTime}{' '}
							{endTime && endTime !== startTime
								? ' - ' + endTime
								: null}
						</div>
					</div>
				);
			})}
		</div>
	);
};

ItemOverview.defaultValues = {
	days: [],
};

ItemOverview.propTypes = {
	slug: PropTypes.string,
	name: PropTypes.string,
	location: PropTypes.string,
	days: PropTypes.array,
	instructor: PropTypes.object,
	organisers: PropTypes.array,
	logo: PropTypes.string,
};

export default ItemOverview;
