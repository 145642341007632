import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'core/store/authentication/auth.actions';

import { PillsBar, PillsContent } from 'components/Pills';

import { Contact, Password, Personal } from './AccountComponents';

const tabs = [
	{ name: 'personal', label: 'Persoonsgegevens' },
	{ name: 'contact', label: 'Contactgegevens' },
	{ name: 'password', label: 'Wachtwoord' },
];

const Account = () => {
	const dispatch = useDispatch();
	const [selectedTab, setSelectedTab] = useState(tabs[0].name);
	const { user } = useSelector(state => state.authReducer);

	const handleSubmit = body => {
		dispatch(updateUser(body));
	};

	return (
		<div>
			<PillsBar
				tabs={tabs}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
			/>
			<PillsContent selectedTab={selectedTab}>
				<Contact
					name='contact'
					user={user}
					userEmail={user.email}
					userSettings={user.settings}
					userPhoneNumber={
						user?.telephone?.length !== 0
							? user.telephone[0]
							: { number: '', visible: false }
					}
					userSocials={user.socialMedia}
					submit={handleSubmit}
				/>
				<Personal
					name='personal'
					user={user}
					userFunction={user.function ?? ''}
					userBirthday={user.birthday}
					submit={handleSubmit}
				/>
				<Password name='password' user={user} submit={handleSubmit} />
			</PillsContent>
		</div>
	);
};

export default Account;
