import React from 'react';
import { useDarkMode } from 'core/hooks/useDarkMode/useDarkMode';

import { Container } from 'components/Grid/Grid';
import Toggle from 'components/Theme/Toggle';

import SocialMenu from './SocialMenu/SocialMenu';

import './CrownBar.scss';

const CrownBar = () => {
	const [theme, mode, toggleTheme] = useDarkMode();

	return (
		<div className='crownBar'>
			<Container>
				<div>
					<SocialMenu />
				</div>

				<Toggle theme={theme} mode={mode} toggleTheme={toggleTheme} />
			</Container>
		</div>
	);
};

export default CrownBar;
