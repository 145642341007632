import axios from 'axios';
import snackbarFactories from 'core/factories/snackbar.factories';
import { errorHelper } from 'core/helpers/message.helpers/error.message.helpers';

import { API_URL, APP_KEY, types } from '../action.constants';

export const changeMode = mode => (dispatch, getState) => {
	const state = getState();
	const { token } = state.authReducer;

	const header = {
		headers: { Authorization: 'Bearer ' + token, 'x-up-app': APP_KEY },
	};
	axios
		.put(
			`${API_URL}/auth/me/settings`,
			{ settings: { theme: mode } },
			header,
		)
		.then(({ data: { data } }) => {
			const darkThemeMediaQuery = window.matchMedia(
				'(prefers-color-scheme: dark)',
			);
			const mode = data.theme;

			dispatch({ type: types.THEME_MODE_UPDATE, payload: { mode } });
			dispatch({
				type: types.THEME_UPDATE,
				payload: {
					theme:
						mode === 'auto'
							? darkThemeMediaQuery.matches
								? 'dark'
								: 'light'
							: mode,
				},
			});
		})
		.catch(error => {
			console.log(error);
			dispatch({
				type: types.TOAST_ADD,
				payload: {
					toast: snackbarFactories({
						text: 'Het wijzigen van je voorkeuren is mislukt vanwege een onverwachte fout.',
						type: 'error',
					}),
				},
			});
		});
};

export const changeTheme = theme => dispatch => {
	dispatch({ type: types.THEME_UPDATE, payload: { theme } });
};

export const loadQuestions = () => (dispatch, getState) => {
	dispatch({
		type: types.QUESTIONS_LOAD,
		payload: { questions: [], questionsLoading: true },
	});
	const state = getState();
	const { token } = state.authReducer;

	const header = {
		headers: { Authorization: 'Bearer ' + token, 'x-up-app': APP_KEY },
	};
	axios
		.get(`${API_URL}/questions`, header)
		.then(({ data: { data } }) => {
			dispatch({
				type: types.QUESTIONS_LOAD,
				payload: { questions: data, questionsLoading: false },
			});
		})
		.catch(error => {
			const response = error.response;
			const message = errorHelper(
				'questions',
				'read',
				response,
				error.response.status,
			);
			dispatch({
				type: types.TOAST_ADD,
				payload: {
					toast: snackbarFactories({ text: message, type: 'error' }),
				},
			});
		});
};
