import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileIcon } from 'core/helpers/file.helpers';
import { downloadAttachment } from 'core/store/attachment/attachment.action';

import Button from 'components/Button/Button';

import './Attachments.scss';

const Attachments = ({ links, attachments }) => {
	const dispatch = useDispatch();

	return (
		<div className='attachments'>
			<span>Bijlagen</span>

			{links && links.length > 0
				? links.map(link => (
						<div className='attachment' key={`l-${link.id}`}>
							<span>
								<Link
									to={link.url}
									target='_blank'
									className='btn btn-link'
									rel='nofollow noopener noreferrer'>
									<FontAwesomeIcon
										icon={faGlobeEurope}
										fixedWidth
									/>
									{link.name}
								</Link>
							</span>
						</div>
					))
				: null}

			{attachments && attachments.length > 0
				? attachments.map(attachment => {
						let icon = getFileIcon(attachment.mime);

						return (
							<div
								className='attachment'
								key={`a-${attachment.id}`}>
								<span>
									<Button
										type='button'
										buttonStyle='link'
										onClick={() =>
											dispatch(
												downloadAttachment(attachment),
											)
										}>
										<FontAwesomeIcon
											icon={icon}
											fixedWidth
										/>
										{attachment.description}
									</Button>
								</span>
							</div>
						);
					})
				: null}
		</div>
	);
};

export default Attachments;
